import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useAPI from "../../../hooks/useApi";
import ImportModal from "./ImportModal";

function Menu() {

    return <div className="flex flex-col">
        <div className="my-2 text-sm text-slate-700">
            MENU
        </div>
        <Link to="/dashboard/files" className="block flex-1 mt-2 " >
            <div className={"cursor-pointer py-2 px-5 rounded-xl text-sm font-light "+(window.location.pathname === '/dashboard/files' ?" bg-indigo-600 text-white":" text-indigo-900 hover:bg-indigo-100 bg-transparent")}>
                <span>
                    <i className="fa-regular fa-folder text-lg"></i> <span className="mx-2">Files</span>
                </span>
            </div>
        </Link>
        <Link to="/dashboard/chatbot" className="block flex-1 mt-2">
            <div className={"cursor-pointer py-2 px-5 rounded-xl text-sm font-light "+(window.location.pathname === '/dashboard/chatbot'?" bg-indigo-600 text-white":" text-indigo-900 hover:bg-indigo-100 bg-transparent")}>
                <span>
                    <i className="fas fa-robot text-lg"></i> <span className="mx-2">Chatbot</span>
                </span>
            </div>
        </Link>
    </div>
}

function Sidebar({ setFiles }: { setFiles: (files: any) => void }) {
    const { createFile, getFiles, uploadFile } = useAPI();
    const [showImportModal, setShowImportModal] = useState(false);
    useEffect(() => {
        getFiles().then((data) => {
            setFiles(data);
        }); //eslint-disable-next-line
    }, [showImportModal]);
    const navigate = useNavigate();

    return (
        <div className="w-[300px]  flex flex-col py-8 px-4 m-4 rounded-3xl bg-[#f7f7fc]">
            <div className="mx-auto font-semibold text-xl">Trilogy</div>
            <input type="file" accept=".csv" className="hidden" id="upload-file-hidden-input" onChange={(event) => {
                const file = event.target.files![0];
                if (file) {
                    const reader = new FileReader();

                    reader.onload = async function (e) {
                        try {
                            const csvData = (e.target?.result?.toString() || '');
                            (document.getElementById("upload-file-hidden-input") as any)!.value = '';
                            const res = await uploadFile(csvData);
                            navigate('/dashboard/file/' + res._id);
                        } catch (error) {
                            alert('Error parsing JSON');
                            console.error('Error parsing JSON:', error);
                        }
                    };
                    reader.onerror = function (e) {
                        alert('Error reading file');
                        console.error('Error reading file:', e.target?.error);
                    };
                    reader.readAsText(file);
                }
            }} />
            <button className="mt-8  bg-violet-200 rounded-xl text-violet-950 py-2 px-5" onClick={async () => {
                setShowImportModal(true);
            }}>
                <i className="fa-brands fa-google-drive mr-2" />Import G-Sheet
            </button>

            {showImportModal && <ImportModal showImportModal={showImportModal} setShowImportModal={setShowImportModal} />}

            <button className="mt-2  bg-violet-200 rounded-xl text-violet-950 py-2 px-5" onClick={async () => {
                document.getElementById("upload-file-hidden-input")?.click();
            }}>
                <i className="fas fa-upload mr-2" />Upload File
            </button>
            <button className="mb-8 mt-2  bg-[#4956F4] rounded-xl text-white py-2 px-5" onClick={async () => {
                await createFile();
                setFiles(await getFiles());
            }}>
                + New File
            </button>

            <Menu />
        </div>
    );
}

export default Sidebar;