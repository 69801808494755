import { useEffect } from "react";
import { useAuth } from "../../../contexts/Auth";
import useAPI from "../../../hooks/useApi";
import FileRow from "./FileRow";

function Files({ files, setFiles }: { files: any, setFiles: (files: any) => void }) {
    const { user } = useAuth();
    console.log(user);
    const { getFiles } = useAPI();

    useEffect(() => {
        getFiles().then((data) => {
            setFiles(data);
        });
    } //eslint-disable-next-line
        , []);


    return (
        <div className="flex-1 p-8 flex flex-col">
            <div className="flex">
                <h1 className="text-2xl font-semibold">Corporate Tree Files</h1>
                <div className="ml-auto flex">
                    <img referrerPolicy="no-referrer" src={user.picture} className="rounded-full h-8 w-8 " alt="" />
                    <div className="mx-2 my-auto">
                        {user.name}
                    </div>
                </div>
            </div>

            <div className="bg-white p-6 my-4 flex-1 flex flex-col rounded-3xl h-[calc(100vh-200px)]">
                <div className="flex items-center px-4 py-2 border-b font-medium text-sm border-black">
                    <span className="mx-2">Name</span>
                </div>
                <div className="flex flex-col overflow-y-scroll">
                    {files?.map((file: any) => <FileRow key={file._id} file={file} setFiles={setFiles} />)}
                </div>
            </div>
        </div>

    );
}

export default Files;   