import GoogleSignIn from "../components/GoogleSignIn";
import { useAuth } from "../contexts/Auth";

export default function LoginPage(){
    const { setUser } = useAuth();
    const handleLoginSuccess = (user: any) => {
        setUser(user);
      };

    return (
        <div className="flex h-screen w-screen">
            <div className="m-auto">
                <div className="py-8 px-4 font-semibold text-xl">corporate tree manager</div>
                <div className="flex">
                    <div className="m-auto">
                        <GoogleSignIn onLoginSuccess={handleLoginSuccess} />
                    </div>
                </div>
            </div>
            
        </div>
    );
}